import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import VueCurrencyFilter from 'vue-currency-filter'

Vue.config.productionTip = false

import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'

// Import Bootstrap an BootstrapVue CSS files (order is important)
import './stylesheets/main.scss'

// Make BootstrapVue available throughout your project
Vue.use(BootstrapVue)
// Optionally install the BootstrapVue icon components plugin
Vue.use(IconsPlugin)

import Toast from "vue-toastification";
import "vue-toastification/dist/index.css";
Vue.use(Toast, {});

import axios from 'axios'
import VueAxios from 'vue-axios'
Vue.use(VueAxios, axios)

// meta tags
import VueMeta from 'vue-meta'
Vue.use(VueMeta, {
  // optional pluginOptions
  refreshOnceOnNavigation: true
})

import { StripePlugin } from '@vue-stripe/vue-stripe';

Vue.use(StripePlugin, {
  pk: "pk_test_51IUdO9Gy2QUGNF3ZRtD36Zreiv46nn13w7jg5rpm9cVgC9DSN9gdqFu4bAgJPnfiwO1w0mNP3f2jNyaH3Op0uA0K00dexdX5Z8",
});

Vue.use(VueCurrencyFilter, {
  symbol : '€',
  thousandsSeparator: '.',
  fractionCount: 2,
  fractionSeparator: ',',
  symbolPosition: 'front',
  symbolSpacing: true,
  avoidEmptyDecimals: undefined,
})

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
