import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'

import store from '../store'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/about',
    name: 'About',
    component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  },
  {
    path: '/cart',
    name: 'Cart',
    component: () => import(/* webpackChunkName: "Cart" */ '../views/Cart.vue')
  },
  {
    path: '/register',
    name: 'Register',
    component: () => import(/* webpackChunkName: "Register" */ '../views/Register.vue')
  },
  {
    path: '/login',
    name: 'Login',
    component: () => import(/* webpackChunkName: "Login" */ '../views/Login.vue')
  },
  {
    path: '/confirm_email/:email',
    name: 'ConfirmEmail',
    component: () => import(/* webpackChunkName: "ConfirmEmail" */ '../views/ConfirmEmail.vue')
  },
  {
    path: '/validate_email/:confirmation_token?',
    name: 'ValidateEmail',
    component: () => import(/* webpackChunkName: "ValidateEmail" */ '../views/ValidateEmail.vue')
  },
  {
    path: '/reset_password/:token?',
    name: 'ResetPassword',
    component: () => import(/* webpackChunkName: "ResetPassword" */ '../views/ResetPassword.vue')
  },
  {
    path: '/cart/review',
    name: 'CartReview',
    component: () => import(/* webpackChunkName: "CartReview" */ '../views/CartReview.vue')
  },
  {
    path: '/cart/payment',
    name: 'CartPayment',
    component: () => import(/* webpackChunkName: "CartPayment" */ '../views/CartPayment.vue')
  },
  {
    path: '/cart/thanks',
    name: 'CartThanks',
    component: () => import(/* webpackChunkName: "CartThanks" */ '../views/CartThanks.vue')
  },
  {
    path: '/orders/complete',
    name: 'OrderComplete',
    component: () => import(/* webpackChunkName: "OrderComplete" */ '../views/OrderComplete.vue')
  },
  {
    path: '/orders/:id/collect',
    name: 'OrderCollect',
    component: () => import(/* webpackChunkName: "OrderCollect" */ '../views/OrderCollect.vue')
  },
  {
    path: '/user/profile',
    name: 'UserProfile',
    component: () => import(/* webpackChunkName: "UserProfile" */ '../views/UserProfile.vue')
  },
  {
    path: '/user/orders',
    name: 'UserOrders',
    component: () => import(/* webpackChunkName: "UserOrders" */ '../views/UserOrders.vue')
  },
  {
    path: '/data-protection',
    name: 'DataProtection',
    component: () => import(/* webpackChunkName: "UserOrders" */ '../views/DataProtection.vue')
  },
  {
    path: '/legal-notice',
    name: 'LegalNotice',
    component: () => import(/* webpackChunkName: "UserOrders" */ '../views/LegalNotice.vue')
  },
  {
    path: '/impressum',
    name: 'Impressum',
    component: () => import(/* webpackChunkName: "UserOrders" */ '../views/Impressum.vue')
  },
  {
    path: '/forum/threads',
    name: 'Forum',
    component: () => import(/* webpackChunkName: "UserOrders" */ '../views/Forum.vue')
  },
  {
    path: '/forum/threads/new',
    name: 'ThreadNew',
    component: () => import(/* webpackChunkName: "UserOrders" */ '../views/ThreadsNew.vue')
  },
  {
    path: '/coupon',
    name: 'Coupon',
    component: () => import(/* webpackChunkName: "UserOrders" */ '../views/Coupon.vue')
  }
]

// component: () syntax
// route level code-splitting
// this generates a separate chunk (about.[hash].js) for this route
// which is lazy-loaded when the route is visited.

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

const routesPrivate = ['UserOrders', 'UserProfile'];
const routesAuth = ['Login', 'Register'];

router.beforeEach((to, from, next) => {
  if(routesPrivate.includes(to.name) && !store.getters['AuthModule/loggedIn']) {
    next({ name: "Login" })
  } else if(routesAuth.includes(to.name) && store.getters['AuthModule/loggedIn']) {
    next({ name: "Home" })
  } else {
    next();
  }
})


export default router
