import Vue from 'vue'
import Vuex from 'vuex'
import { AuthModule } from "./auth.module";
import createPersistedState from "vuex-persistedstate";

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    theme: "dark",
    cart: [],
    loading: false,
    coupon: null,
  },
  mutations: {
    changeTheme(state, payload) {
      state.theme = payload;
    },
    updateCart(state, cart) {
      state.cart = cart;
    },
    updateLoading(state, payload) {
      state.loading = payload;
    },
    updateCoupon(state, coupon) {
      state.coupon = coupon;
    },
  },
  actions: {
  },
  getters: {
    wholeTheme: state => {
      return state.theme;
    },
    cart: state => {
      return state.cart;
    },
    coupon: state => {
      return state.coupon;
    },
    loading: state => {
      return state.loading;
    }
  },
  modules: {
    AuthModule
  },
  plugins: [createPersistedState({
    key: 'whole'
  })],
})
