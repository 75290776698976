import instance from './base.service';

class ProductService {
  getAll() {
    return instance.get(`api/products.json`)
  }

  getAllGroupedByCategories() {
    return instance.get(`api/products/grouped.json`)
  }

  verifyMaxProductsSold(products, coupon_id = null) {
    return instance.post(`api/products/verify_max_product_sold.json`, {
      products,
      coupon_id
    });
  }

  // presign() {
  //   return instance.get(`api/uploader/sign.json`);
  // }

  // getStats(slug, id) {
  //   return instance.get(`api/sites/${slug}/links_stats.json?site_link_id=${id}`)
  // }

  // update(slug, params) {
  //   return instance.post(`api/sites/${slug}/save_links.json`, params)
  // }
}

export default new ProductService();
