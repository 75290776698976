import instance from './base.service';

class AuthService {
  login(user) {
    return instance
      .post('api/sign_in.json', {
        email: user.email,
        password: user.password
      })
      .then((response) => {
        return Promise.resolve(response);
      }).catch((error) => {
        return Promise.reject(error.response);
      })
  }

  logout() {
    return instance.delete('session.json').then(response => {
      return Promise.resolve(response);
    }).catch(error => {
      return Promise.reject(error.response);
    })
  }

  register(user) {
    return instance.post('api/sign_up.json', {user}).then(response => {
      return Promise.resolve(response);
    }).catch((error) => {
      return Promise.reject(error.response);
    })
  }

  confirm(token) {
    return instance.put(`api/confirmations.json?confirmation_token=${token}`, {}).then(response => {
      return Promise.resolve(response);
    }).catch((error) => {
      return Promise.reject(error.response);
    })
  }

  resend(form) {
    return instance.post(`users/confirmation.json`, {user: form}).then(response => {
      return Promise.resolve(response);
    })
  }

  reset(user) {
    return instance.post(`api/passwords.json`, user).then(response => {
      return Promise.resolve(response);
    })
  }

  reset_password(user) {
    return instance.put(`api/passwords.json`, {user: user}).then(response => {
      return Promise.resolve(response);
    })
  }
}

export default new AuthService();
