<template>
  <div v-if="loading" class="container-loader position-fixed d-flex justify-content-center align-items-center">
    <b-spinner large />
  </div>
</template>

<script>
  export default {
    name: 'Loader',
    computed: {
      loading() {
        return this.$store.getters.loading
      }
    },
  }
</script>

<style lang="scss" scoped>
  .container-loader {
    top: 0;
    width: 100vw;
    height: 100vh;
    background: rgba(0, 0, 0, 0.6);
    z-index: 999;
  }
</style>