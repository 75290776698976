import axios from 'axios';
import router from '../router'
import store from '../store'
import Vue from "vue";

const instance = axios.create({
  baseURL: process.env.VUE_APP_API_URL,
})

// before a request is made start the nprogress
instance.interceptors.request.use(config => {
  store.commit('updateLoading', true)
  if(store.getters['AuthModule/jwtToken'])
    config.headers.Authorization = `${store.getters['AuthModule/jwtToken']}`

  return config
})

instance.interceptors.response.use(response => {
  store.commit('updateLoading', false)

  return response;
}, function (error) { //error
  store.commit('updateLoading', false)

  console.log(error);

  try {
    switch(error.response.status) {
      case 500:
        // Vue.$toast("We're under high load, please wait few moments and try again", {
        //   timeout: 3000
        // });
        Vue.$toast(error.response.data.toString(), {
          timeout: 3500
        });
        Promise.reject(error);
        // Promise.reject(error);
        // Promise.reject(error);
      break;
      case 408:
        Vue.$toast("hey folx, so many of you are trying to get a ticket right now and we are experiencing a really high volume of traffic on the shop site. Please be patient and wait for a few minutes until you are able to complete your order. We're really sorry for the inconvenience! Thank you for understanding <3", {
          timeout: 3000
        });
        Promise.reject(error);
      break;

      case 404:
        // alert('404')
        router.push(`/login`)
      break;

      case 302:
        //store.dispatch('AuthModule/logout', {})
        router.push(`/login`)
        // store.commit('logout');

      break;

      case 401:
        store.dispatch('AuthModule/logout')

        Vue.$toast("Your access token has expired, try connecting again", {
          timeout: 2000
        });

        router.push(`/login`)
      break;

      case 406:
        router.push(`/login`)
      break;

      case 422:
        Vue.$toast(error.response.data.errors[0], {
          timeout: 2000
        });
        Promise.reject(error);
        break;

      default:
        // Vue.$toasts
        Promise.reject(error);
    }
  } catch(_) {
    //   store.dispatch('auth/logoutOffline');
    return Promise.reject(_);
  }
  // NProgress.done()
});

export default instance
