<template>
  <div class="product-row-container">
    <div :class="['product-row', this.handleClass]">
      <div class="row">
        <div class="col-4 col-md-2">
          <div class="product-date">
            <span class="day">{{ product.product_date }}</span>
            <span class="month">{{ product.product_month }}</span>
            <span class="time">
              {{ product.product_time }}
            </span>
          </div>
        </div>

        <div class="col-8 col-md-6">
          <div class="product-meta">
            <h4>{{ product.name }}</h4>
            <p>{{ product.description }}</p>
          </div>
        </div>

        <div class="col-6 col-md-2">
          <div v-if="!product.sold" class="product-quantity">
            <b-form-spinbutton
              v-model="quantity"
              min="0"
              :max="product.maximum_quantity"
              @change="verifyQuantity()"
              :disabled="product.sold"
            />
          </div>
        </div>

        <div class="col-6 col-md-2">
          <div class="product-price">
            <span>{{ priceTotalAndVat | currency }}</span>
            <b-btn variant="outline-primary" @click="onSelect" v-if="!isSelected && quantity > 0">select</b-btn>
            <p v-if="product.sold">sold out</p>
          </div>
        </div>
      </div>
    </div>

    <div class="product-setup-row" v-if="isSelected && quantity > 0">
      <div class="form-alert" v-if="false">
        <div class="container">
          <div class="row">
            <div class="col-2 col-md-1">
              <AlertCircleIcon class="color-bg-svg" />
            </div>
            <div class="col col-md-8">
              <p>Due to our safety, all participants have to do a COVID test at the door or before getting in the busses and shuttle. If you have taken the vaccine you don’t have to do the test, but you will need to bring the vaccination documents with you.</p>
            </div>
            <div class="col-2 col-md-1 offset-md-2 text-right">
              <XIcon @click="isSelected = false" class="color-bg-svg" />
            </div>
          </div>
        </div>
      </div>

      <div class="setup-container">
        <div class="container" v-if="!isShuttle">
          <div class="ticket-flex-container">
            <div class="ticket-card" v-for="ticket in tickets" :key="ticket.id">
              <div class="ticket-card-header">
                <h4>Ticket {{ticket.id}}</h4>
                <b-btn variant="outline-light" class="delete-button" @click="onDeleteTicket(ticket.id)">
                  delete
                  <XRedIcon />
                </b-btn>
              </div>
              <div class="ticket-card-body" v-if="isTicket || isParking || isCovidTest">
                <b-form-group label="Name / Chosen name on Ticket" class="custom">
                  <b-form-input v-model="ticket.ticket_name" />
                </b-form-group>
                <b-form-checkbox
                  v-model="ticket.covidTest"
                  :value="true"
                  :unchecked-value="false"
                  v-if="false"
                >
                  COVID test {{ covidFee | currency}}
                </b-form-checkbox>
              </div>
              <div class="ticket-card-footer">
                <p>
                  Subtotal
                  <span>{{ subTotal(ticket) | currency }}</span>
                </p>
              </div>
            </div>
          </div>

          <div class="setup-actions">
            <b-btn variant="outline-primary" block @click="onAddAnotherTicket" v-if="quantity < product.maximum_quantity">
              + Add another ticket
            </b-btn>
          </div>

          <div class="setup-footer">
            <div class="content">
              <p>
                Total
                <span>{{ productTotal | currency}}</span>
              </p>
              <b-btn variant="primary" @click="onAddToCart" :disabled="verifyTicketsName">
                <ShoppingCartWhiteIcon />
                Add to cart
              </b-btn>
            </div>
          </div>
        </div>
        <div class="container" v-if="isShuttle">
          <div class="row ticket-flex-container">
            <div class="col-md-12">
              <h2>Select your one-way shuttle ticket</h2>
              <p>Our busses leaves on time from or to BUS PLATFORM C or D - Dessau Hauptbahnhof.</p>
            </div>
            <div class="col-md-12">
              <div v-for="(ticket) in tickets" :key="ticket.id">
                <div class='row'>
                  <div class='col-md-9'>
                    <div class="ticket-card-body">
                      <b-form-group label="Name / Chosen name on Ticket *" class="custom">
                        <b-form-input v-model="ticket.ticket_name" />
                      </b-form-group>
                    </div>
                    <div class="ticket-card-body">
                      <div class="row">
                        <div class='col-md-3 direction'>
                          <b-form-group label="Departure" class="custom_shuttle">
                            <b-form-select v-model="ticket.shuttle_departure" @change="changeDeparture(ticket)" :options="ticket.optionsDeparture" />
                          </b-form-group>
                        </div>
                        <div class='col-md-3 direction'>
                          <b-form-group label="Arrival" class="custom_shuttle">
                            <b-form-select v-model="ticket.shuttle_arrival" @change="changeArrival(ticket)" :options="ticket.optionsArrival" />
                          </b-form-group>
                        </div>
                        <div class='col-md-3 day'>
                          <b-form-group label="Day" class="custom_shuttle">
                            <b-form-select v-model="ticket.day" @change="changeDay(ticket)" :options="ticket.optionsDay" />
                          </b-form-group>
                        </div>
                        <div class='col-md-3 hour'>
                          <b-form-group label="Hour" class="custom_shuttle">
                            <b-form-select v-model="ticket.hour" :options="ticket.options_hour" />
                          </b-form-group>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class='col-md-3'>
                    <b-btn variant="outline-danger" class="delete-button" @click="onDeleteTicket(ticket.id)">
                      <XRedIcon />
                    </b-btn>
                  </div>
                </div>
              </div>
            </div>
            <div class='col-md-5 add-passenger-shuttle '>
              <a href="javascript:void(0)" @click="onAddAnotherTicket" v-if="quantity < product.maximum_quantity">
                + ADD ANOTHER RIDE
              </a>
            </div>
            <div class='col-md-6 add-cart-shuttle' style=''>
              <div class="setup-footer">
            <div class="content">
              <p>
                Sub total
                <span>{{ productTotal | currency }}</span>
              </p>

              <b-btn variant="primary" @click="onAddToCart" :disabled="verifyTicketsName || verifyShutter">
                <ShoppingCartWhiteIcon />
                Add to cart
              </b-btn>
            </div>
          </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Toast from "@/mixin/toast"
import ProductService from '@/services/product.service'
import ShuttlerService from "@/services/shuttler.service"
import AlertCircleIcon from "@/assets/icons/alert-circle.svg";
import XIcon from "@/assets/icons/x.svg";
import XRedIcon from "@/assets/icons/x-red.svg";
import ShoppingCartWhiteIcon from "@/assets/icons/shopping-cart-white.svg";

export default {
  name: 'ProductTicket',
  props: {
    product: {
      id: Number,
      name: String,
      description: String,
      valid_at: String, //"27.08 Aug 2020 Fr 14",
      product_date: String, //"27.08",
      product_month: String, //"Aug 2021",
      product_time: String, //"Fr 14:00",
      price_text: String, //"&euro; 79.00",
      price: Number, // 79.0,
      maximum_quantity: Number, //3,
      quantity: Number, //0
    },
  },
  data() {
    return {
      quantity: 0,
      isSelected: false,
      tickets: [],
      busy: false,
      covidFee: 10
    }
  },
  mixins: [ Toast ],
  mounted() {
    this.initialize()
  },
  computed: {
    cart(){
      return this.$store.getters.cart;
    },
    handleClass(){
      return this.product.sold ? "opacity" : "";
    },
    verifyTicketsName(){
      if(this.isTicket || this.isShuttle || this.isParking || this.isCovidTest){
        return !!this.tickets.filter(ticket => ticket.ticket_name == '').length > 0;
      }else{
        return false;
      }
    },
    verifyShutter(){
      return !!this.tickets.filter(ticket => ticket.shuttle_arrival == '' || ticket.day == '' || ticket.hour == '').length > 0;
    },
    subTotal: function () {
      return function (ticket) {
        return this.isTicket && ticket.covidTest ? (Number(this.priceTotalAndVat) + this.covidFee) : this.priceTotalAndVat;
      }
    },
    productTotal(){
      return (this.priceTotalAndVat * this.quantity) + (this.tickets.filter(ticket => ticket.covidTest).length * this.covidFee);
    },
    priceTotalAndVat(){
      return Number(this.product.price) + Number(this.product.price_vat);
    },
    isTicket(){
      return this.product.product_type.includes('ticket');
    },
    isShuttle(){
      return this.product.product_type == 'product_type_shuttle';
    },
    isParking(){
      return this.product.product_type == 'product_type_parking';
    },
    isCovidTest(){
      return this.product.product_type == 'product_type_covid_test';
    }
  },
  methods: {
    initialize() {
      if(this.cart.length > 0){
        let has_tickets = this.cart.find(product => product.id == this.product.id);
        if(has_tickets){
          this.tickets = has_tickets.tickets;
          this.quantity = has_tickets.quantity;
        } else {
          this.tickets = [];
          this.quantity = 0;
        }
      }
    },
    onSelect() {
      this.isSelected = true;
    },
    onAddAnotherTicket() {
      if(this.tickets.length < this.product.maximum_quantity) {
        this.quantity++
        this.verifyMaxProductsSold();
      }
    },
    onDeleteTicket(ticket_id) {
      if(this.quantity > 0) {
        this.tickets.splice(this.tickets.findIndex(ticket => ticket.id == ticket_id), 1)
        this.quantity--;
        this.updateQuantity();
      }
    },
    onAddToCart() {
      this.updateQuantity();
      this.$bvToast.show(`toastAddToCart`, {
        noAutoHide: false,
        appendToast: true,
        noCloseButton: false,
      })
    },
    updateQuantity(){
      if(this.quantity == 0){
        this.$store.commit('updateCart', this.cart.filter(product => product.id != this.product.id));
      } else {
        this.product.quantity = this.quantity;
        this.product.tickets = this.tickets;
        let total = this.product.price * this.product.quantity
        for(let ticket of this.product.tickets){
          if(ticket.covidTest){
            total += 10;
          }
        }
        this.product.total = total;
        const index = this.cart.findIndex(product => product.id == this.product.id)
        index == -1 ? this.cart.push(this.product) : (this.cart[index] = this.product);
        this.$store.commit('updateCart', this.cart);
      }
    },
    changeDeparture(ticket){
      ticket.optionsDay = [];
      ticket.optionsArrival = [];
      ticket.options_hour = [];
      ticket.shuttle_arrival = '';
      ticket.day = '';
      ticket.hour = '';
      ShuttlerService.getArrivalByDeparture(ticket.shuttle_departure).then((result) => {
        ticket.optionsArrival.push([]);
        ticket.optionsArrival.push(...result.data);
      }).catch(() => {
        this.showToast('warning', 'Error', 'No shuttles available')
      })
    },
    changeArrival(ticket){
      ticket.optionsDay = [];
      ticket.options_hour = [];
      ticket.day = '';
      ticket.hour = '';
      ShuttlerService.getArrivalDate(ticket.shuttle_departure, ticket.shuttle_arrival).then((result) => {
        ticket.optionsDay.push([]);
        ticket.optionsDay.push(...result.data);
      })
    },
    changeDay(ticket){
      ticket.options_hour = [];
      const days = ticket.optionsDay.filter(day => day.event_day == ticket.day)
      for(const day of days){
        for(const vehicle of day.shuttle_vehicles){
          const time = {
            value: vehicle.id,
            text: vehicle.departure,
            disabled: vehicle.sold >= vehicle.maximum_quantity
          }
          ticket.options_hour.push(time);
        }
      }
    },
    verifyQuantity() {
      if(this.quantity < this.tickets.length){
        this.tickets.pop();

        this.updateQuantity();
      } else if (this.quantity > this.tickets.length && this.tickets.length < this.product.maximum_quantity){
        this.verifyMaxProductsSold()
      }
    },
    verifyMaxProductsSold(){
      this.busy = true;
      ProductService.verifyMaxProductsSold([{product_id: this.product.id, quantity: this.quantity}]).then((result) => {
        this.busy = false;
        if(result.data.status){
          this.showToast('danger', 'Error', result.data.errors[0]);
          this.quantity--;
        } else {
          if(this.tickets.length < this.product.maximum_quantity){
            let ticket = {
              id: new Date().getTime(),
              ticket_name: '',
              covidTest: false,
              shuttle_departure: '',
              shuttle_arrival: '',
              day: '',
              hour: '',
              options_day: [],
              options_hour: [],
              optionsDeparture: [],
              optionsArrival: [],
              optionsDay: [],
            }
            if(this.isShuttle){
              ticket.optionsDeparture = [];
              if(this.isShuttle){
                ShuttlerService.getAll().then((result) => {
                  ticket.optionsDeparture.push([])
                  ticket.optionsDeparture.push(...result.data);
                })
              }
            }
            this.tickets.push(ticket);
          }
        }
      }, (error) => {
        this.busy = false;
        console.error(error);
      })
    }
  },
  components: {
    AlertCircleIcon,
    XIcon,
    XRedIcon,
    ShoppingCartWhiteIcon
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.product-row-container {
  &:not(:first-child) {
    margin-top: 7px;
  }
  .opacity {
    opacity: 0.4;
  }

  .product-row {
    // display: flex;
    background: var(--whole-bg-lighter);

    padding: 20px 30px;

    .product-date {
      text-align: center;
      .day {
        display: block;
        font-weight: 400;
        font-size: 29px;
      }

      .month {
        display: block;
        font-size: 18px;
      }

      .time {
        display: block;
        font-size: 14px;
      }
    }
    .product-meta {
      h4 {
        font-size: 29px;
        font-weight: 700;
      }
      p {
        font-size: 18px;
      }
    }
    .product-quantity {
      font-size: 18px;
      text-align: center;

    }
    .product-price {

      text-align: right;
      display: flex;
      justify-content: flex-end;
      align-content: space-between;
      flex-wrap: wrap;
      height: 100%;

      span {
        display: block;
        font-size: 29px;
        font-weight: 700;

        text-align: right;
        line-height: 100%;
      }

      .btn {
        font-weight: 500;
        font-size: 14px;
        text-transform: uppercase;
        border-radius: 0;
      }

      p {
        margin-bottom: 0px;

        font-size: 16px;
        font-weight: 500;
        text-transform: uppercase;

        color: var(--whole-danger);
      }
    }
  }

  .product-setup-row {

    .setup-container {
      background: var(--whole-black-form-bg);;
      padding: 1rem 0;
    }

    .ticket-flex-container {
      column-count: 2;
      column-gap: 20px;
      row-gap: 20px;
      margin: 2rem 0;
      // https://stackoverflow.com/questions/14925157/css-columns-with-left-right-flow

      @media screen and (max-width: 500px) {
        column-count: 1;
      }

      .ticket-card {
        border: 1px solid var(--whole-text);
        break-inside: avoid;
        // width: 48%;
        padding: 1rem;
        margin-bottom: 20px;

        .ticket-card-header {
          display: flex;
          justify-content: space-between;
          align-items: center;
          margin-bottom: 30px;

          h4 {
            font-size: 18px;
            font-weight: 700;
          }
          .btn {
            text-transform: uppercase;
            // background: transparent;
            font-size: 14px;
            font-weight: 500;
            display: flex;
            // justify-content: center;
            align-items: center;
            border: 0;
            color: var(--whole-text);

            padding: 0;
            img {
              // width: 12px;
              // height: 12px;
              margin-left: 12px;
            }
          }
        }

        .ticket-card-body {
          legend {
            text-transform: uppercase;
          }

          .form-control {
            border-color: var(--whole-text);
          }
        }

        .ticket-card-footer {
          display: flex;
          justify-content: flex-end;
          align-items: center;
          margin-top: 24px;
          p {
            font-size: 18px;
            span {
              font-weight: 700;
              font-size: 18px;
            }
          }
        }
      }
    }

    .setup-actions {
      column-count: 2;
      column-gap: 20px;
      margin: 2rem 0;
      @media screen and (max-width: 500px) {
        column-count: 1;
      }
      .btn {
        break-inside: avoid;
        text-transform: uppercase;
        font-weight: 500;
        font-size: 18px;
      }
    }

    .setup-footer {
      .content {
        display: flex;
        justify-content: flex-end;
        align-items: center;

        p {
          display: inline-block;
          margin: 0;
          font-size: 18px;
          font-weight: 400;
          span {
            display: inline-block;
            margin-left: 18px;
            font-weight: 700;
          }
        }

        .btn {
          display: inline-block;
          text-transform: uppercase;
          margin: 0;
          margin-left: 18px;
          font-weight: 500;
          font-size: 18px;
          display: flex;
          justify-content: center;
          align-items: center;
          border-radius: 0;
          img {
            margin-right: 10px;
          }
        }
      }
    }
    .add-cart-shuttle {
      margin: 2rem 0;
      text-align: right;
    }

    .add-passenger-shuttle {
      margin: 2rem 0;
      text-align: left;
    }

  }

  .custom_shuttle select {
    border-color: var(--whole-text);
    background: var(--whole-black-form-bg);
    font-size: 18px;
    color: var(--whole-text);
    padding: 0.4rem;
  }
}
</style>
