import AuthService from '../services/auth.service';

export const AuthModule = {
  namespaced: true,
  state: {
    status: {
      loggedIn: false
    },
    user: null,
  },
  getters: {
    loggedIn: state => {
      return state.status.loggedIn
    },
    jwtToken: state => {
      return state.user && state.user.jwt ? state.user.jwt : null;
    }
  },
  actions: {
    async login({ commit }, user) {
      return await AuthService.login(user).then(
        result => {
          commit('loginSuccess', result);
          return Promise.resolve(result);
        },
        error => {
          commit('loginFailure');
          return Promise.reject(error);
        }
      );
    },
    logout({ commit }) {
      commit('logout');
    },
    register({ commit }, user) {
      return AuthService.register(user).then(
        result => {
          commit('registerSuccess', result);
          return Promise.resolve(result);
        },
        error => {
          commit('registerFailure');
          return Promise.reject(error);
        }
      );
    },
    confirm({ commit }, token) {
      return AuthService.confirm(token).then(result => {
        commit('confirmSuccess', result);
        return Promise.resolve(result);
      },
      error => {
        commit('confirmFailure');
        return Promise.reject(error);
      })
    },
    resetPassword({ commit }, user) {
      return AuthService.reset_password(user).then(
        result => {
          result.data.status ? commit('resetSuccess', result) : commit('resetFailure');
          return Promise.resolve(result);
        },
        error => {
          commit('resetFailure');
          return Promise.reject(error);
        }
      );
    },
  },
  mutations: {
    loginSuccess(state, user) {
      state.status.loggedIn = true;
      state.user = user.data;
      if(user.data.jwt) {
        state.user.jwt = user.data.jwt;
      }
    },
    loginFailure(state) {
      state.status.loggedIn = false;
      state.user = null;
    },
    logout(state) {
      state.status.loggedIn = false;
      state.user = null;
    },
    registerSuccess(state, result) {
      state.status.loggedIn = true;
      state.user = result.data;
      if(result.data.jwt) {
        state.user.jwt = result.data.jwt;
      }
    },
    registerFailure(state) {
      state.status.loggedIn = false;
    },
    confirmSuccess(state, user) {
      state.status.loggedIn = true;
      state.user = user.data;
      if(user.data.jwt) {
        state.user.jwt = user.data.jwt;
      }
    },
    confirmFailure(state) {
      state.status.loggedIn = false;
    },
    resetSuccess(state, user) {
      console.log("resetSuccess")
      state.status.loggedIn = true;
      state.user = user.data;
      if(user.data.jwt) {
        state.user.jwt = user.data.jwt;
      }
    },
    resetFailure(state) {
      console.log("resetFailure")
      state.status.loggedIn = false;
    },
  }
};
