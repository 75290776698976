<template>
  <div class="home">
    <div class="landing-content">
      <h1>United Queer Festival</h1>
      <h3>From 26 to 28 of august.</h3>
      <p>FERROPOLIS, City of Iron</p>
    </div>

    <div>
      <div v-for="category in categories" :key="category.id">
        <div v-if="category.products && category.products.length > 0" class="product-section-header">
          <h1>{{ category.name }}</h1>
          <p>{{ category.description }}</p>
        </div>
        <div v-if="category.products && category.products.length > 0" class="product-listing">
          <product-ticket :product="product" v-for="product in category.products" :key="product.id"/>
        </div>
      </div>
    </div>
    <!-- <div style="text-align: center">
      <h3>TICKETS WILL BE AVAILABLE FROM THE 3RD of MARCH</h3>
    </div> -->

    <!-- hey folx, so many of you have tried to get a ticket and due to a really high volume of traffic on the shop site we have decided to continue shopping tomorrow, Thursday 10th of March at 16:00 Berlin time. We’re really sorry for the inconvenience! Thank you for understanding <3 -->

  </div>
</template>

<script>
import ProductTicket from '@/components/ProductTicket.vue'
import ProductService from '@/services/product.service'

export default {
  name: 'Home',
  metaInfo: {
    title: 'United Queer Festival',
  },
  components: {
    ProductTicket
  },
  data() {
    return {
      categories: [],
      covidTestPrice: 0
    }
  },
  mounted() {
    this.initialize()
  },
  methods: {
    initialize() {
      ProductService.getAllGroupedByCategories().then((result) => {
        console.log(result.data);
        this.categories = result.data;
      })
    }
  }
}
</script>

<style scoped lang="scss">
// index / landing page
.landing-content {
  text-align: center;
  margin: 4rem 0 8rem;

  h1 {
    font-size: 76px;
    font-weight: bold;
  }

  h3 {
    font-weight: 700;
    font-size: 29px;
    margin: 0;
  }

  p {
    font-weight: 400;
    font-size: 18px;
    margin: 0;
  }

  @media screen and (max-width: 500px) {
    margin: 2rem 0 4rem;

    h1 {
      font-size: 2.75rem;
    }

    h3 {
      font-size: 1.6rem;
    }
  }
}



.product-section-header {
  margin-top: 4rem;
  h1 {
    margin-bottom: 0.5rem;
    font-size: 47px;
    font-weight: 700;
    @media screen and (max-width: 500px) {
      font-size: 2rem;
    }
  }
  @media screen and (max-width: 500px) {
    margin-top: 2.5rem;
  }
  p {
    font-size: 18px;
    font-weight: 400;
    margin-bottom: 0;
  }
}

.product-listing {
  margin-top: 4.2rem;

  @media screen and (max-width: 500px) {
    margin-top: 2rem;
  }
}

</style>
