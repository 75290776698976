<template>
  <div id="app">
    <Loader/>

    <b-btn class="themeChooser" @click.stop.prevent="changeTheme"></b-btn>
    <div class="whole-header">
      <div class="container">
        <div class="row">
          <div class="col-3 left-menu">
            <router-link to="/">Home</router-link>
          </div>
          <div class="col-6 logo-menu">
            <router-link to="/">
              <WholeLogo />
            </router-link>
          </div>
          <div class="col-3 right-menu">
            <router-link to="/user/orders">
              <UserIcon />
            </router-link>
            <router-link to="/cart/review" :class="['cart-link', { hasItems: true }]">
              <ShoppingCartIcon />
              <div class="cart-badge" v-if="cart.length > 0"></div>
            </router-link>
          </div>
        </div>
      </div>
    </div>

    <div class="container">
      <!-- flash messages -->

      <transition name="fade">
        <router-view/>
      </transition>

    </div>

    <div class="whole-footer">
      <div class="container">
        <div class="row">
          <div class="col-12 col-md-3">
            <router-link to="/">
              <WholeLogo class="mb-2" />
            </router-link>
          </div>
          <div class="col-6 col-md-2">
            <ul class="list-unstyled">
              <!-- <li><a href="#">FAQ</a></li> -->
              <li><a href="mailto: ticketing@wholefestival.com">Contact</a></li>
            </ul>
          </div>
          <div class="col-6 col-md-2">
            <ul class="list-unstyled">
              <li><a href="/data-protection">Data Protection</a></li>
              <li><a href="/legal-notice">Legal Notice</a></li>
              <!-- <li><a href="#">Cancellation</a></li> -->
              <li><a href="/impressum">Impressum</a></li>
            </ul>
          </div>
          <div class="col-12 col-md-5">
            <div class="support-logos">
              <div class="logo-content">
                <DieBlackLogo class="footer-logo black" />
                <DieWhiteLogo class="footer-logo white" />
              </div>
              <div class="logo-content">
                <GemaBlackLogo class="footer-logo black" />
                <GemaWhiteLogo class="footer-logo white" />
              </div>
              <div class="logo-content">
                <NeuBlackLogo class="footer-logo black" />
                <NeuWhiteLogo class="footer-logo white" />
              </div>
            </div>
          </div>
        </div>
      </div>

    </div>

    <b-toast id="toastAddToCart" variant="success" solid class="customToasts">
      <template #toast-title>
      </template>
      <div class="row">
        <div class="col-2">
          <CheckCircleIcon />
        </div>
        <div class="col-10">
          The item was added to your cart.<br>
          <router-link to="/cart/review">Check out</router-link>
        </div>
      </div>
    </b-toast>

    <b-toast id="toastTicketName" variant="success" solid class="customToasts">
      <template #toast-title>
      </template>
      <div class="row">
        <div class="col-2">
          <CheckCircleIcon />
        </div>
        <div class="col-10">
          You have changed your ticket name.<br>
          <!-- <router-link to="/user/orders">Download Ticket</router-link> -->
        </div>
      </div>
    </b-toast>

    <b-toast id="toastError" variant="danger" solid class="customToasts" title=''>
      <template #toast-title>
      </template>
      <div class="row">
        <div class="col-2">
          <CheckCircleIcon />
        </div>
        <div class="col-10">
          You have changed your ticket name
        </div>
      </div>
    </b-toast>
  </div>
</template>

<script>
import WholeLogo from "@/assets/logo-pink.svg";
import UserIcon from "@/assets/icons/user.svg";
import ShoppingCartIcon from "@/assets/icons/shopping-cart.svg";
import CheckCircleIcon from "@/assets/icons/check-circle.svg";

import Loader from "@/components/Loader";

import DieBlackLogo from "@/assets/die_black.svg";
import DieWhiteLogo from "@/assets/die_white.svg";
import GemaBlackLogo from "@/assets/gema_black.svg";
import GemaWhiteLogo from "@/assets/gema_white.svg";
import NeuBlackLogo from "@/assets/neu_black.svg";
import NeuWhiteLogo from "@/assets/neu_white.svg";

export default {
  name: 'WholeApp',
  metaInfo() {
    return {
      title: 'WHOLE Festival',
      titleTemplate: '%s | WHOLE Festival',
      htmlAttrs: {
        lang: 'en',
        amp: false,
      },
      bodyAttrs: { class: this.wholeTheme },
    }
  },
  methods:{
    changeTheme() {
      this.$store.commit('changeTheme', this.wholeTheme == 'dark' ? 'light' : 'dark');
    }
  },
  computed: {
    wholeTheme() {
      return this.$store.getters.wholeTheme;
    },
    cart(){
      return this.$store.getters.cart;
    }
  },
  mounted() {

  },
  components: {
    WholeLogo,
    UserIcon,
    ShoppingCartIcon,
    CheckCircleIcon,
    Loader,
    DieBlackLogo,
    DieWhiteLogo,
    GemaBlackLogo,
    GemaWhiteLogo,
    NeuBlackLogo,
    NeuWhiteLogo
  }
}
</script>

<style lang="scss" scoped>
.themeChooser {
  position: absolute;
  top: 10px;
  left: 10px;
  background: var(--whole-text);
  width: 15px;
  height: 15px;
  display: block;
  overflow: hidden;
  padding: 0;
  margin: 0;
  border-radius: 250%;
  border: 1px solid var(--whole-bg);
  &:active,&:focus {
    background: var(--whole-text);
    box-shadow: none !important;
    outline: 0 !important;
  }
}
.slither-enter-active, .slither-leave-active {
  transition: transform 1s;
}

.slither-enter, .slither-leave-to {
  transform: translateX(-100%);
}

.slither-enter-to, .slither-leave {
  transform: translateX(0);
}

.drain-enter-active, .drain-leave-active {
  transition: transform 1s;
}

.drain-enter, .drain-leave-to {
  transform: translateY(100%);
}

.drain-enter-to, .drain-leave {
  transform: translateY(0);
}

.fade-enter-active, .fade-leave-active {
  transition: opacity .35s
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0
}

.whole-header {
  padding: 2rem 0;
  margin-top: 1rem;

  .row {
    align-items: center;

    .left-menu {
      font-size: 29px;
      font-weight: 700;
      font-family: var(--custom-font-family);;

      a {
        color: var(--whole-text);
      }
    }

    .logo-menu {
      padding: 0px;
      
      a {
        width: 100%;

        display: flex;
        justify-content: center;

        svg {
          overflow: visible
        }
      }
    }

    .right-menu {
      text-align: right;

      a:not(:first-child) {
        margin-left: 50px;
      }

      .cart-link {
        // border: 1px solid #fff;
        position: relative;

        .cart-badge {
          position: absolute;
          top: 0;
          right: -3px;
          width: 12px;
          height: 12px;
          background: var(--whole-danger);;
          border-radius: 250%;
          display: none;
        }

        &.hasItems {
          .cart-badge {
            display: block;
          }
        }
      }
    }
  }

  @media screen and (max-width: 500px) {
    // padding: .7rem 0;
    margin-top: 0rem;

    .row {
      .left-menu {
        font-size: 1rem;
      }
      .logo-menu {
        svg {
          // width: 100%;
          // height: auto;
          transform: scale(.75) translateX(-10px);
        }
      }

      .right-menu {
        display: flex;
        justify-content: space-between;
        a:not(:first-child) {
          margin-left: 0;
        }
      }
    }
  }
}

// footer
.whole-footer {
  .container {
    border-top: 1px solid var(--whole-text);
    margin-top: 12rem;
    margin-bottom: 4rem;
    padding-top: 2rem;

    a {
      color: var(--whole-text);
    }

    svg {
      transform: scale(0.65) translate(-70px, -10px);
    }

    .footer-title {
      text-transform: uppercase;
    }

    .support-logos {
      display: flex;
      justify-content: space-between;
      // margin-top: 1rem;

      > div.logo-content {
        display: block;
        min-width: 100px;
        padding: 10px;
        
        > svg, > img {
          display: block;
          transform: none;
          width: 100%;
          height: auto;
        }

      }

      // > div, > img, > svg {
      //   display: block;

      // }

    }
  }
}
</style>

<style lang="scss">
body.dark {
  .support-logos {
    svg.black {
      display: none !important;
    }
    svg.white {
      display: block !important;
    }
  }
}
body.light {
  .support-logos {
    svg.black {
      display: block !important;
    }
    svg.white {
      display: none !important;
    }
  }
}
</style>
