import instance from './base.service';

class ShuttlerService {

  getAll() {
    return instance.get(`api/shuttles/locations.json`)
  }

  getArrivalByDeparture(shuttle_location_id) {
    return instance.get(`api/shuttles/arrival_by_departure.json?shuttle_location_id=${shuttle_location_id}`)
  }

  getArrivalDate(shuttle_location_id, shuttle_location_arrival_id) {
    return instance.get(`api/shuttles/arrival_date.json?shuttle_location_id=${shuttle_location_id}&shuttle_location_arrival_id=${shuttle_location_arrival_id}`)
  }
}

export default new ShuttlerService();
